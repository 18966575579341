export default class WorksYtoTableItem {
    workYtoId = 0;
    workYtoStatus = 0;
    workCode = "";
    processInstanceId = "";
    reporterCode = "";
    reporterName = "";
    reporterMobile = "";
    exceptionStartTime = "";
    taskId = "";
    workOrderNo = "";
    assetCode = "";
    deviceCode = "";
    belongOrgCode = "";
    belongOrgName = "";
    urgency = "";
    deviceType = "";
    operatingArea = "";
    exceptionDesc = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";

    handlerCode = "";
    handlerName = "";
    handlerMobile = "";
    handleDesc = "";
    handleStatus = 0;
    handleTime = "";
    handleMessage = "";
}
